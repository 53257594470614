.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.qr-canvas-container {
  width: 300px;
  height: 300px;
  overflow: hidden; /* Ensure canvas doesn't overflow */
}
.qr-canvas-container canvas {
  width: 600px; /* Original canvas width */
  height: 600px; /* Original canvas height */
  transform: scale(0.5); /* Scale the canvas to fit within the container */
  transform-origin: 0 0; /* Set the transformation origin to top left */
}
